import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import pick from 'lodash/pick';

import { PoolType } from 'declarations/exclusives/listing';
import { MarketLocation } from 'declarations/exclusives/market';

import {
  Filters,
  INITIAL_FILTERS,
  PROPERTY_TYPE_STATUS_ARRAY,
  PropertyTypeValues,
} from 'helpers/exclusives/filterHomes';

import { MARKET_NEIGHBORHOODS, NeighborhoodInfo } from '../../../helpers/exclusives/neighborhoods';

export const DEFAULT_MARKET_IDENTIFIER: Uppercase<MarketLocation> = 'DALLAS';

export const NEIGHBORHOODS_KEYED_BY_UPPERCASE_MARKET: Record<
  Uppercase<MarketLocation>,
  NeighborhoodInfo[]
> = Object.entries(MARKET_NEIGHBORHOODS).reduce((acc, [market, neigbhorhoods]) => {
  acc[market.toUpperCase() as Uppercase<MarketLocation>] = neigbhorhoods;
  return acc;
}, {} as Record<Uppercase<MarketLocation>, NeighborhoodInfo[]>);

export const bedroomsItems = [
  {
    label: 'Any',
    value: 0,
    ariaLabel: 'Bedroom Option',
  },
  {
    label: '1+',
    value: 1,
    ariaLabel: 'Bedroom Option',
  },
  {
    label: '2+',
    value: 2,
    ariaLabel: 'Bedroom Option',
  },
  {
    label: '3+',
    value: 3,
    ariaLabel: 'Bedroom Option',
  },
  {
    label: '4+',
    value: 4,
    ariaLabel: 'Bedroom Option',
  },
];

export const bathroomsItems = [
  {
    label: 'Any',
    value: 0,
    ariaLabel: 'Bathroom Option',
  },
  {
    label: '1+',
    value: 1,
    ariaLabel: 'Bathroom Option',
  },
  {
    label: '2+',
    value: 2,
    ariaLabel: 'Bathroom Option',
  },
  {
    label: '3+',
    value: 3,
    ariaLabel: 'Bathroom Option',
  },
  {
    label: '4+',
    value: 4,
    ariaLabel: 'Bathroom Option',
  },
];

export const poolTypeItems = Object.values(PoolType).map((value) => ({
  value,
  label: value.split('_').map(capitalize).join(' '),
}));

export type MarketItem = {
  label: string;
  value: Uppercase<MarketLocation>;
  ariaLabel: string;
};

// TODO: move this into ../helpers/exclusives
export const marketItems: MarketItem[] = [
  {
    label: 'Dallas',
    value: 'DALLAS',
    ariaLabel: 'City',
  },
  {
    label: 'Raleigh-Durham',
    value: 'RALEIGH',
    ariaLabel: 'City',
  },
  {
    label: 'Charlotte',
    value: 'CHARLOTTE',
    ariaLabel: 'City',
  },
];

export type Zone = {
  gdsId: string;
};

export type FeedPropertiesFilter = {
  minPrice: number;
  maxPrice: number;
  minBedrooms: number | null;
  minBathrooms: number | null;
  minSqft: number | null;
  maxSqft: number | null;
  minLotSqft: number | null;
  maxLotSqft: number | null;
  minYearBuilt: number | null;
  maxYearBuilt: number | null;
  propertyTypes: PropertyTypeValues[] | null;
  zones: Zone[];
};

// This is the default filter returned from athena for a new user
export const FEED_PROPERTIES_FILTER_ATHENA_API_DEFAULT_RESPONSE: FeedPropertiesFilter = {
  minPrice: 0,
  maxPrice: 999999999,
  minBedrooms: null,
  minBathrooms: null,
  minSqft: null,
  maxSqft: null,
  minLotSqft: null,
  maxLotSqft: null,
  minYearBuilt: null,
  maxYearBuilt: null,
  propertyTypes: ['HOME', 'APARTMENT', 'TOWNHOME', 'MULTI_FAMILY'],
  zones: [],
};

// This is being necessary to handle the initial filter from a new user
// and properly allow us to set the initial filter with proper values
export function isFeedPropertiesFilterFromAthenaEqualToTheInitial(
  feedPropertiesFilter: FeedPropertiesFilter,
) {
  return isEqual(feedPropertiesFilter, FEED_PROPERTIES_FILTER_ATHENA_API_DEFAULT_RESPONSE);
}

export function mergeFilters(filters: Filters, partialFilters: Partial<Filters>) {
  return Object.entries(partialFilters).reduce((acc, [key, val]) => {
    if (val) {
      (acc[key as keyof Filters] as any) = val;
    }
    return acc;
  }, cloneDeep(filters));
}

const FILTER_PREFERENCE_IS_ENABLED: Record<keyof Filters, boolean> = {
  priceRangeMax: true,
  priceRangeMin: true,
  bedrooms: true,
  bathrooms: true,
  buildingSizeMax: true,
  buildingSizeMin: true,
  lotSizeMax: true,
  lotSizeMin: true,
  yearBuiltMax: true,
  yearBuiltMin: true,
  neighborhoods: true,
  propertyTypes: true,
  homeStatus: true,
  sellerType: true,
  listedWhereStatus: true,
};

const FILTER_PREFERENCE_KEYS: (keyof Filters)[] = (
  Object.entries(FILTER_PREFERENCE_IS_ENABLED) as [keyof Filters, boolean][]
)
  .filter(([_, isEnabled]) => isEnabled)
  .map(([filterName]) => filterName);

function customIsEqualForFilters(a: Filters, b: Filters) {
  return Object.entries(a).every(([key, val]) => {
    const bVal = b[key as keyof Filters];
    if (Array.isArray(val) && Array.isArray(bVal)) {
      return val.length === bVal.length && !difference(val, bVal).length;
    }
    return isEqual(val, bVal);
  });
}

export function isEqualFilters(filtersA: Filters, filtersB: Filters) {
  return isEqualWith(
    pick(filtersA, FILTER_PREFERENCE_KEYS),
    pick(filtersB, FILTER_PREFERENCE_KEYS),
    customIsEqualForFilters,
  );
}

export function isPreferencesOrFiltersOutdated(
  filters: Filters,
  athenaPreferencesFilters?: Filters,
) {
  const isEqualInitialFilters = isEqualFilters(filters, INITIAL_FILTERS);
  return athenaPreferencesFilters
    ? !isEqualInitialFilters && !isEqualFilters(filters, athenaPreferencesFilters)
    : !isEqualInitialFilters;
}

export function filtersDataFromFeedPropertiesFilter(
  market: Uppercase<MarketLocation>,
  feedPropertiesFilter: FeedPropertiesFilter,
): {
  filters: Filters;
  selectedNeighborhoods: NeighborhoodInfo[] | undefined;
} {
  const neighborhoods = NEIGHBORHOODS_KEYED_BY_UPPERCASE_MARKET[market];
  // neighborhoods are stored as UUIDs in athena so find the full neighborhood objects
  // that correspond to them (so we can use in query params)
  let selectedNeighborhoods;
  if (feedPropertiesFilter['zones']) {
    const selectedNeighborhoodUuidsSet = new Set(
      feedPropertiesFilter['zones'].map((zone) => zone['gdsId']),
    );
    if (selectedNeighborhoodUuidsSet.size !== neighborhoods.length) {
      selectedNeighborhoods = neighborhoods.filter((neighborhood) =>
        selectedNeighborhoodUuidsSet.has(neighborhood.uuid as string),
      );
    }
  }
  let propertyTypes =
    feedPropertiesFilter['propertyTypes']?.filter((pt) =>
      PROPERTY_TYPE_STATUS_ARRAY.includes(pt),
    ) || [];
  if (propertyTypes.length && propertyTypes.length === PROPERTY_TYPE_STATUS_ARRAY.length) {
    propertyTypes = [];
  }
  return {
    selectedNeighborhoods,
    filters: isFeedPropertiesFilterFromAthenaEqualToTheInitial(feedPropertiesFilter)
      ? cloneDeep(INITIAL_FILTERS)
      : mergeFilters(INITIAL_FILTERS, {
          priceRangeMin: feedPropertiesFilter['minPrice'] * 100,
          priceRangeMax:
            (!feedPropertiesFilter['maxPrice'] ||
            feedPropertiesFilter['maxPrice'] ===
              FEED_PROPERTIES_FILTER_ATHENA_API_DEFAULT_RESPONSE.maxPrice
              ? 0
              : feedPropertiesFilter['maxPrice']) * 100,
          bathrooms: feedPropertiesFilter['minBathrooms'] || 0,
          bedrooms: feedPropertiesFilter['minBedrooms'] || 0,
          buildingSizeMin: feedPropertiesFilter['minSqft'] || 0,
          buildingSizeMax: feedPropertiesFilter['maxSqft'] || 0,
          lotSizeMin: feedPropertiesFilter['minLotSqft'] || 0,
          lotSizeMax: feedPropertiesFilter['maxLotSqft'] || 0,
          yearBuiltMin: feedPropertiesFilter['minYearBuilt'] || 0,
          yearBuiltMax: feedPropertiesFilter['maxYearBuilt'] || 0,
          propertyTypes,
          neighborhoods: selectedNeighborhoods?.map(({ id }) => id) || [],
        }),
  };
}

export function getSelectedNeighborhoods(
  neighborhoodIdentifiers: string[] | undefined,
  marketNeighborhoods: NeighborhoodInfo[],
) {
  if (!neighborhoodIdentifiers) return [];
  const neighborHoodsSet = new Set(neighborhoodIdentifiers);
  return marketNeighborhoods.filter(({ id }) => neighborHoodsSet.has(id));
}
