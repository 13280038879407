import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

export const FILTERS_SESSION_KEY = '@cosmos/exclusives/filters';

export interface Filters {
  priceRangeMin: number;
  priceRangeMax: number;
  bedrooms: number;
  bathrooms: number;
  buildingSizeMin: number;
  buildingSizeMax: number;
  lotSizeMin: number;
  lotSizeMax: number;
  yearBuiltMin: number;
  yearBuiltMax: number;
  neighborhoods: string[];
  propertyTypes: PropertyTypeValues[];
  homeStatus: HomeStatusValues[] | undefined;
  sellerType: SellerTypeValues[] | undefined;
  listedWhereStatus: ListedWhereStatusValues[] | undefined;
}

export interface QueryParamsFilters {
  priceRangeMin: number;
  priceRangeMax: number;
  bedrooms: number;
  bathrooms: number;
  buildingSizeMin: number;
  buildingSizeMax: number;
  lotSizeMin: number;
  lotSizeMax: number;
  yearBuiltMin: number;
  yearBuiltMax: number;
  neighborhoods: string | string[];
  propertyTypes: PropertyTypeValues[] | undefined;
  homeStatus: HomeStatusValues[] | undefined;
  sellerType: SellerTypeValues[] | undefined;
}

export const HomeStatus = {
  ForSale: 'for_sale',
  AvailableSoon: 'available_soon',
  InContract: 'in_contract',
} as const;

export const SellerType = {
  Opendoor: 'opendoor',
  PrivateSeller: 'private_seller',
} as const;

export const ListedWhereStatus = {
  OpendoorOnly: 'opendoor_only',
  Publicly: 'publicly',
} as const;

export const HOME_STATUS_ARRAY = [
  HomeStatus.ForSale,
  HomeStatus.AvailableSoon,
  HomeStatus.InContract,
];

export const SELLER_TYPE_ARRAY = [SellerType.Opendoor, SellerType.PrivateSeller];

export const LISTED_WHERE_STATUS_ARRAY = [
  ListedWhereStatus.OpendoorOnly,
  ListedWhereStatus.Publicly,
];

export const PropertyTypes = {
  Apartment: 'APARTMENT',
  Home: 'HOME',
  Townhome: 'TOWNHOME',
  Commercial: 'COMMERCIAL',
  Land: 'LAND',
  MultiFamily: 'MULTI_FAMILY',
  MobileHome: 'MOBILE_HOME',
  Rental: 'RENTAIL',
  Unclassified: 'UNCLASSIFIED',
  UnknownPropertyType: 'UNKNOW_PROPERTY_TYPE',
} as const;

export const PROPERTY_TYPE_STATUS_ARRAY: PropertyTypeValues[] = [
  PropertyTypes.Apartment,
  PropertyTypes.Home,
  PropertyTypes.Townhome,
];

export type HomeStatusValues = (typeof HomeStatus)[keyof typeof HomeStatus];
export type SellerTypeValues = (typeof SellerType)[keyof typeof SellerType];
export type PropertyTypeValues = (typeof PropertyTypes)[keyof typeof PropertyTypes];
export type ListedWhereStatusValues = (typeof ListedWhereStatus)[keyof typeof ListedWhereStatus];

export const INITIAL_FILTERS: Filters = {
  bedrooms: 0,
  bathrooms: 0,
  yearBuiltMin: 0,
  yearBuiltMax: 0,
  priceRangeMin: 0,
  priceRangeMax: 0,
  neighborhoods: [],
  buildingSizeMin: 0,
  buildingSizeMax: 0,
  lotSizeMin: 0,
  lotSizeMax: 0,
  propertyTypes: [],
  homeStatus: [],
  sellerType: [],
  listedWhereStatus: [ListedWhereStatus.OpendoorOnly],
};

function getPropertyTypeText(propertyType: PropertyTypeValues): string {
  switch (propertyType) {
    case PropertyTypes.Apartment:
      return 'condo';
    default:
      return propertyType.toLowerCase().replace('_', ' ');
  }
}

const FILTERS_TYPE: Record<keyof Filters, 'number' | 'array' | 'string' | 'boolean'> = {
  priceRangeMax: 'number',
  priceRangeMin: 'number',
  yearBuiltMax: 'number',
  yearBuiltMin: 'number',
  buildingSizeMax: 'number',
  buildingSizeMin: 'number',
  lotSizeMax: 'number',
  lotSizeMin: 'number',
  bathrooms: 'number',
  bedrooms: 'number',
  neighborhoods: 'array',
  propertyTypes: 'array',
  homeStatus: 'array',
  sellerType: 'array',
  listedWhereStatus: 'array',
};

const FILTER_STRING_PARSER = {
  number: (val: string) => {
    const value = Number(val);
    return Number.isNaN(value) ? 0 : value;
  },
  array: (val: string) => val.split(',').map((v) => v.trim()),
} as const;

export function getFiltersFromQuery(parsedQuery: NodeJS.Dict<string | string[]>): Filters {
  return Object.entries(parsedQuery).reduce((filters, [key, value]) => {
    if (value && ['string', 'number'].includes(typeof value) && key in filters) {
      (filters[key as keyof Filters] as Filters[keyof Filters]) = FILTER_STRING_PARSER[
        FILTERS_TYPE[key as keyof Filters] as keyof typeof FILTER_STRING_PARSER
      ](value as string);
    }
    return filters;
  }, cloneDeep(INITIAL_FILTERS));
}

export function getQueryParamsFromFilters(filters: Filters): NodeJS.Dict<string | string[]> {
  return Object.entries(filters).reduce((queryParams, [key, value]) => {
    if (
      !isEqual(value, INITIAL_FILTERS[key as keyof Filters]) &&
      value !== undefined &&
      value !== null
    ) {
      queryParams[key] = isArray(value) ? value.join(',') : value.toString();
    }
    return queryParams;
  }, {} as NodeJS.Dict<string | string[]>);
}

export function saveFiltersOnSession(filters: Filters) {
  sessionStorage.setItem(FILTERS_SESSION_KEY, JSON.stringify(filters));
}

export function getFiltersFromSession(): Filters | undefined {
  const filtersString = sessionStorage.getItem(FILTERS_SESSION_KEY);
  return filtersString ? JSON.parse(filtersString) : undefined;
}

export function isEqualInitialFilters(
  filters: Filters,
  {
    initialFilters = INITIAL_FILTERS,
    keys,
  }: { initialFilters?: Filters; keys?: (keyof Filters)[] } = {},
) {
  if (keys) {
    return isEqual(pick(filters, ...keys), pick(initialFilters, ...keys));
  }
  return isEqual(filters, initialFilters);
}

export function getActiveFiltersText({
  priceRangeMax,
  priceRangeMin,
  buildingSizeMax,
  buildingSizeMin,
  lotSizeMax,
  lotSizeMin,
  bedrooms,
  bathrooms,
  yearBuiltMax,
  yearBuiltMin,
  neighborhoods,
  propertyTypes,
}: Filters) {
  const filtersArrayText = [];
  if (priceRangeMax) {
    if (priceRangeMin) {
      filtersArrayText.push(
        `$${(priceRangeMin / 100).toLocaleString()} - $${(priceRangeMax / 100).toLocaleString()}`,
      );
    } else {
      filtersArrayText.push(`Up to $${(priceRangeMax / 100).toLocaleString()}`);
    }
  } else if (priceRangeMin) {
    filtersArrayText.push(`At least $${(priceRangeMin / 100).toLocaleString()}`);
  }
  if (bedrooms) {
    filtersArrayText.push(`${bedrooms}+ beds`);
  }
  if (bathrooms) {
    filtersArrayText.push(`${bathrooms}+ baths`);
  }
  if (buildingSizeMax) {
    filtersArrayText.push(`building size ${buildingSizeMin} sqft - ${buildingSizeMax} sqft`);
  } else if (buildingSizeMin) {
    filtersArrayText.push(`min building size ${buildingSizeMin} sqft`);
  }
  if (lotSizeMax) {
    filtersArrayText.push(`lot size ${lotSizeMin} sqft - ${lotSizeMax} sqft`);
  } else if (lotSizeMin) {
    filtersArrayText.push(`min lot size ${lotSizeMin} sqft`);
  }
  if (yearBuiltMax && yearBuiltMin) {
    filtersArrayText.push(`year built ${yearBuiltMin} - ${yearBuiltMax}`);
  } else if (yearBuiltMin) {
    filtersArrayText.push(`min year built ${yearBuiltMin}`);
  } else if (yearBuiltMax) {
    filtersArrayText.push(`max year built ${yearBuiltMax}`);
  }
  if (propertyTypes.length) {
    filtersArrayText.push(`property type ${propertyTypes.map(getPropertyTypeText).join(', ')}`);
  }
  return (
    filtersArrayText.join(', ') +
    (neighborhoods.length
      ? ` in ${
          neighborhoods.length > 3
            ? `${neighborhoods.slice(0, 4).join(', ')} and ${neighborhoods.length} other cities`
            : neighborhoods.join(', ')
        }`
      : '')
  );
}

const URL_SEARCH_PARAM_KEY_FROM_FILTER_KEY_ON_BE: Record<keyof Filters, string> = {
  bedrooms: 'min_bedrooms',
  bathrooms: 'min_bathrooms',
  yearBuiltMin: 'min_year_built',
  yearBuiltMax: 'max_year_built',
  priceRangeMin: 'min_price',
  priceRangeMax: 'max_price',
  neighborhoods: 'cities',
  buildingSizeMin: 'min_sq_ft',
  buildingSizeMax: 'max_sq_ft',
  lotSizeMin: 'min_lot_sq_ft',
  lotSizeMax: 'max_lot_sq_ft',
  homeStatus: 'exclusive_states',
  propertyTypes: 'property_types',
  sellerType: 'seller_types',
  listedWhereStatus: 'listed_where',
};

/**
 * These are the values current available on the
 * web api and we are basically converting the values from Athena to it.
 * https://github.com/opendoor-labs/web/blob/aaeb8e7590010855030f2770873aeee182cb10d0/app/models/listing.rb#L59
 *
 * DWELLING_TYPES = %w(
 *  condo
 *  gemini
 *  loft
 *  mobile
 *  modular
 *  multi-family
 *  patio
 *  single-family
 *  townhouse
 *  half-duplex
 * ).freeze
 */

const PROPERTY_TYPE_VALUES_TO_API: Record<PropertyTypeValues, string[] | undefined> = {
  APARTMENT: ['condo'],
  HOME: ['single-family', 'multi-family', 'half-duplex', 'gemini'],
  TOWNHOME: ['townhouse'],
  COMMERCIAL: undefined,
  LAND: undefined,
  MULTI_FAMILY: undefined,
  MOBILE_HOME: undefined,
  RENTAIL: undefined,
  UNCLASSIFIED: undefined,
  UNKNOW_PROPERTY_TYPE: undefined,
};

const FILTER_VALUE_TO_API_CONVERSION: {
  [key in keyof Filters]: undefined | ((val: Filters[key]) => unknown);
} = {
  priceRangeMin: undefined,
  priceRangeMax: undefined,
  bedrooms: undefined,
  bathrooms: undefined,
  buildingSizeMin: undefined,
  buildingSizeMax: undefined,
  lotSizeMin: undefined,
  lotSizeMax: undefined,
  yearBuiltMin: undefined,
  yearBuiltMax: undefined,
  neighborhoods: undefined,
  propertyTypes: (val) => {
    return val.flatMap((val) => PROPERTY_TYPE_VALUES_TO_API[val]).filter(Boolean);
  },
  homeStatus: undefined,
  sellerType: undefined,
  listedWhereStatus: undefined,
};

function filterIntoURLSearchParam<Key extends keyof Filters>(
  params: URLSearchParams,
  [filterKey, _filterValue]: [Key, Filters[Key]],
) {
  const converter = FILTER_VALUE_TO_API_CONVERSION[filterKey];
  const filterValue = _filterValue && (converter ? converter(_filterValue as any) : _filterValue);
  if (filterValue && !isEqual(INITIAL_FILTERS[filterKey], filterValue)) {
    params.set(
      URL_SEARCH_PARAM_KEY_FROM_FILTER_KEY_ON_BE[filterKey],
      isArray(filterValue) ? filterValue.join(',') : (filterValue as any).toString(),
    );
  }
}

export function filtersIntoURLSearchParamsOnBackend(
  filters?: Filters,
  additionalSearchParams?: Record<string, string> | string[][],
): URLSearchParams {
  const urlSearchParams = new URLSearchParams(additionalSearchParams);
  if (filters) {
    for (const entry of Object.entries(filters)) {
      filterIntoURLSearchParam(urlSearchParams, entry as any);
    }
  }
  return urlSearchParams;
}
