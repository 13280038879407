import { useEffect, useMemo, useState } from 'react';

import { doBFFPost } from 'components/api';

import { EXCLUSIVE_RAILS_HOST } from '../../globals';
import useExclusiveAnalytics from './useExclusiveAnalytics';

export enum SubscriptionState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

type CampaignSubscriptionOptions = {
  args?: string[];
  analyticsAdditionalContextualData?: Record<string, unknown>;
  onSuccess?: () => void;
};

function useCampaignSubscription(
  campaignName: string,
  email: string,
  analyticsLabel: string,
  { args = [], analyticsAdditionalContextualData, onSuccess }: CampaignSubscriptionOptions = {},
) {
  const { trackExclusivesEvent } = useExclusiveAnalytics();
  const stateKey = keyFor(campaignName, email, ...args);
  const [isLoading, setIsLoading] = useState(false);
  const [errorStatusCode, setErrorStatusCode] = useState<number | null>(null);
  const [subscriptionState, setSubscriptionState] = useState(() => {
    return getPersistedState()?.[stateKey] ?? SubscriptionState.INACTIVE;
  });
  const [subscriptionStateOrigin, setSubscriptionStateOrigin] = useState<
    'subscription' | 'persisted_state' | undefined
  >(undefined);

  useEffect(() => {
    const stateKey = keyFor(campaignName, email, ...args);
    const newSubscriptionState = getPersistedState()?.[stateKey] ?? SubscriptionState.INACTIVE;
    setSubscriptionState(newSubscriptionState);
    setSubscriptionStateOrigin(
      newSubscriptionState === SubscriptionState.ACTIVE ? 'persisted_state' : undefined,
    );
  }, [campaignName, email, args]);

  const isSubscribed = useMemo(
    () => subscriptionState === SubscriptionState.ACTIVE,
    [subscriptionState],
  );

  const subscribe = async (
    data: any,
    additionalContextualData?: Record<string, unknown>,
  ): Promise<void> => {
    setIsLoading(true);
    const path = `${EXCLUSIVE_RAILS_HOST}/api/v1/marketing/inbound-campaign-email-capture`;
    try {
      const response = await doBFFPost(path, {
        email,
        event_data: data,
        campaign_name: campaignName,
      });
      if (response.ok) {
        setSubscriptionState(SubscriptionState.ACTIVE);
        setSubscriptionStateOrigin('subscription');
        setPersistedState(stateKey, SubscriptionState.ACTIVE);
        (window as any)?.fbq?.('track', 'ExclusiveListingsSubscribed');
        trackExclusivesEvent('exclusives-subscribe', analyticsLabel, undefined, {
          ...analyticsAdditionalContextualData,
          ...additionalContextualData,
          email: email,
        });
        onSuccess?.();
      } else {
        setErrorStatusCode(response.status);
      }
    } catch (e) {
      setErrorStatusCode(500);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    subscribe,
    errorStatusCode,
    cleanError: () => setErrorStatusCode(null),
    subscriptionState,
    subscriptionStateOrigin,
    isSubscribed,
    isLoading,
  };
}

function keyFor(...args: string[]) {
  return `exclusives-${args.join('-')}`;
}

const subscriptionStateKey = 'exclusives-email-subscriptions';

function getPersistedState(): Record<string, SubscriptionState> | undefined {
  // Making sure it doesn't break if running server side
  if (typeof localStorage === 'undefined') {
    return undefined;
  }

  const persistedState = localStorage.getItem(subscriptionStateKey);
  return persistedState ? JSON.parse(persistedState) : undefined;
}

function setPersistedState(key: string, state: SubscriptionState | undefined) {
  // Making sure it doesn't break if running server side
  if (typeof localStorage === 'undefined') {
    throw new Error('Operation only allowed client side.');
  }

  const nextState = { ...getPersistedState(), [key]: state };
  localStorage.setItem(subscriptionStateKey, JSON.stringify(nextState));
}

export function isPersistedSubscriptionActive(
  campaignName: string,
  email: string,
  ...args: string[]
) {
  const stateKey = keyFor(campaignName, email, ...args);
  return (
    (getPersistedState()?.[stateKey] ?? SubscriptionState.INACTIVE) === SubscriptionState.ACTIVE
  );
}

export default useCampaignSubscription;
