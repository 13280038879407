/* storybook-check-ignore */
import { MouseEvent, useEffect, useState } from 'react';
import Modal, { Props as ModalProps } from 'react-modal';

import styled from '@emotion/styled';
import { Button, Flex, PresentationalRadioGroup, Text } from '@opendoor/bricks/core';
import { GroupOption } from '@opendoor/bricks/core/Input';
import { ThemedLite } from '@opendoor/bricks/theme/ODThemeLite';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import { doBFFPost } from 'components/api';
import { ATHENA_URL } from 'components/globals';
import AccountConnector, { AccountProps } from 'components/shared/AccountConnector';

import {
  MARKET_EMAIL_IDENTIFIERS,
  MARKET_TEXT,
  MarketLocation,
} from 'declarations/exclusives/market';

import useCampaignSubscription from './hooks/useCampaignSubscription';
import useDisabledBodyScroll from './hooks/useDisabledBodyScroll';
import useExclusiveAnalytics from './hooks/useExclusiveAnalytics';
import { saveMarketToAthena } from './PreferencesForm/useAthenaPreferences';

const PopupContainer = styled(motion(Flex))`
  border-radius: 1rem;
  padding: 2.5rem;
  background-color: var(--colors-neutrals0);
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  height: fit-content;
  position: relative;
  z-index: 10;
`;

const MARKET_OPTIONS: (GroupOption & { value: MarketLocation })[] = (
  Object.entries(MARKET_TEXT) as [MarketLocation, string][]
).map(([market, label]) => ({
  value: market,
  label,
}));

export const SHOULD_PRESENT_MARKET_MODAL_LS_KEY = '@cosmos/exclusives/shouldPresentMarketModal';

export function MarketSelectorModalInner({
  isOpen,
  onRequestClose,
  account,
}: Pick<ModalProps, 'isOpen' | 'onRequestClose'> & { account: AccountProps }) {
  const router = useRouter();
  useDisabledBodyScroll(isOpen);
  const email = account.email || account.human?.get('email');
  const { trackExclusivesEvent } = useExclusiveAnalytics();
  function reportEvent(label: string, additionalContextualData?: Record<string, unknown>) {
    trackExclusivesEvent(
      'exclusives-market-selector-modal',
      label,
      undefined,
      additionalContextualData,
    );
  }
  useEffect(() => {
    if (isOpen) {
      reportEvent('viewed', {
        pathname: router.pathname,
      });
    }
  }, [isOpen]);
  const [selectedMarket, setSelectedMarket] = useState<MarketLocation | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const campaignSubscription = useCampaignSubscription('exclusive-listings', email, 'subscribe', {
    analyticsAdditionalContextualData: {
      source: 'market-selector-modal',
    },
  });
  const onNotFoundCity: ModalProps['onRequestClose'] = (ev) => {
    onRequestClose?.(ev);
    reportEvent('desired-market-not-shown');
  };
  const onMarketSelected = (market: string) => {
    setSelectedMarket(market as MarketLocation);
    reportEvent('selected-market', {
      value: market,
    });
  };
  const onConfirm = async (ev: React.MouseEvent | React.KeyboardEvent) => {
    if (selectedMarket) {
      setIsLoading(true);
      campaignSubscription.subscribe({
        market: MARKET_EMAIL_IDENTIFIERS[selectedMarket],
      });
      try {
        await saveMarketToAthena(selectedMarket.toUpperCase() as Uppercase<MarketLocation>).finally(
          () => setIsLoading(false),
        );
        reportEvent('confirmed', {
          value: selectedMarket,
        });
        onRequestClose?.(ev);
      } catch {
        // TOOD: handle error
      }
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={{
        overlay: {
          display: 'flex',
          backgroundColor: 'rgba(34, 34, 34, 0.33)',
          zIndex: 999,
          width: '100vw',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
        },
        content: {
          padding: 0,
          display: 'flex',
          flexShrink: 1,
          flexGrow: 0,
          backgroundColor: 'transparent',
          border: 'none',
          position: 'relative',
          inset: 0,
          overflow: 'hidden',
        },
      }}
    >
      <ThemedLite theme="exclusives">
        <PopupContainer
          onClick={(ev: MouseEvent<HTMLDivElement>) => {
            ev.stopPropagation();
          }}
          animate={{ scale: 1, opacity: 1 }}
          initial={{ scale: 0.5, opacity: 0 }}
          maxWidth="none !important"
        >
          <Flex flexDirection="column" gridGap="1rem">
            <Text fontSize="s3" lineHeight="s1" style={{ fontWeight: 500 }}>
              <Text as="span" fontSize="s0" color="neutrals90">
                Welcome to Opendoor Exclusives!
              </Text>
              <br />
              Where are you looking to buy a home?
            </Text>
            <Text as="span" fontSize="s1" lineHeight="s00">
              We'll only show you homes in the city you choose
            </Text>
            <PresentationalRadioGroup
              aria-label={null}
              hasContainer
              name="cosmos-exclusives-market-selector-radio-group"
              analyticsName="cosmos-exclusives-market-selector-radio-group"
              role="radiogroup"
              options={MARKET_OPTIONS}
              onClick={onMarketSelected}
              selectedValue={selectedMarket}
            />
            <Button
              variant="link"
              aria-label={null}
              analyticsName="cosmos-exclusives-i-dont-see-the-city-im-looking-for-button"
              color="neutrals100"
              textDecoration="none"
              onClick={onNotFoundCity}
              textAlign="center"
            >
              I don't see the city I'm looking for
            </Button>
            <Button
              disabled={!selectedMarket || isLoading}
              loading={isLoading}
              aria-label={null}
              analyticsName="cosmos-exclusives-confirm-city-button"
              onClick={onConfirm}
            >
              {!selectedMarket ? 'Select a city' : 'Confirm city'}
            </Button>
          </Flex>
        </PopupContainer>
      </ThemedLite>
    </Modal>
  );
}

const BUYER_GET_CURRENT_USER_MARKET_QUERY = `
  query BuyerGetCurrentUserMarket{
    buyer {
      getCurrentUser {
        user {
          market
        }
      }
    }
  }
`;

interface BuyerGetCurrentUserMarket {
  data: {
    buyer?: {
      getCurrentUser?: {
        user?: {
          market: Uppercase<MarketLocation> | 'UNKNOWN';
        };
      };
    };
  };
}

class FetchCurrentUserMarketError extends Error {
  constructor(readonly response: Response, readonly additionalContext?: unknown) {
    super(`FetchCurrentUserMarketError: response statusCode ${response.status}`);
  }
}

async function fetchCurrentUserMarket() {
  const response = await doBFFPost(
    ATHENA_URL,
    {
      operationName: null,
      query: BUYER_GET_CURRENT_USER_MARKET_QUERY,
      variables: {},
    },
    { headers: { 'apollographql-client-name': 'cosmos-exclusives' }, retries: 0 },
  );
  if (!response.ok) throw new FetchCurrentUserMarketError(response);
  try {
    const body: BuyerGetCurrentUserMarket = await response.json();
    return body.data.buyer?.getCurrentUser?.user?.market;
  } catch (err) {
    throw new FetchCurrentUserMarketError(response, err);
  }
}

/**
 * Checks if we should present the market modal by retrieving the
 * item SHOULD_PRESENT_MARKET_MODAL_LS_KEY from the localStorage
 * and parsing it
 * @returns boolean
 */
function shouldPresentMarketModal() {
  const shouldPresentMarketModalOnLS = localStorage.getItem(SHOULD_PRESENT_MARKET_MODAL_LS_KEY);
  try {
    return shouldPresentMarketModalOnLS && JSON.parse(shouldPresentMarketModalOnLS);
  } catch {
    return false;
  }
}

export function setShouldPresentMarketModalOnLS(val: boolean) {
  if (typeof document === 'undefined') return;
  localStorage.setItem(SHOULD_PRESENT_MARKET_MODAL_LS_KEY, JSON.stringify(val));
}

function MarketSelectorModal(account: AccountProps) {
  const router = useRouter();
  const isAuthenticated = account.loggedIn && !account.infoIsLoading;
  useSWR(isAuthenticated ? BUYER_GET_CURRENT_USER_MARKET_QUERY : null, fetchCurrentUserMarket, {
    onSuccess: (data) => {
      if (isAuthenticated) {
        const noMarket = !data || data === 'UNKNOWN';
        const shouldOpenModal =
          noMarket &&
          /**
           * The usage of `SHOULD_PRESENT_MARKET_MODAL_LS_KEY` is necessary since the account or any other state
           * related to it doesn't provide a way to identiy if the user has recently authenticated or not. Since the
           * user is able to dismiss the Modal we don't want to present every time, we should only present after
           * register or login process or if we haven't presented it yet since he was already authenticated.
           */
          shouldPresentMarketModal() &&
          !router.pathname.includes('/exclusives/login');
        setIsOpen(shouldOpenModal);
      }
    },
  });
  const isUnauthenticated = !account.loggedIn && !account.infoIsLoading;
  useEffect(() => {
    if (isUnauthenticated) {
      localStorage.removeItem(SHOULD_PRESENT_MARKET_MODAL_LS_KEY);
    }
  }, [isUnauthenticated]);
  const [isOpen, setIsOpen] = useState(false);
  const onRequestClose = () => {
    setIsOpen(false);
    setShouldPresentMarketModalOnLS(false);
  };
  if (!isOpen) return null;
  return (
    <MarketSelectorModalInner isOpen={isOpen} onRequestClose={onRequestClose} account={account} />
  );
}

export default AccountConnector(MarketSelectorModal);
